import styled, { css } from 'styled-components';
import { color, font, mediaBreakpointDown, mediaBreakpointUp, hover, offset } from '@/style/mixins';
import RectLink from '@/components/ui/RectLink/RectLink';
import { Container as Documents, DocumentsWrapper } from '@/components/blocks/Documents/Documents.styled';
import { Container as ProceduresList } from '@/components/blocks/ProceduresList/ProceduresList.styled';
import { Container as ContentSpoiler } from '@/components/blocks/ContentSpoiler/ContentSpoiler.styled';
import { BaseOffsetsUI } from '@/interfaces';
import variables from '@/style/variables';
import { Colors } from '@/style/colors';

export const Container = styled.div<BaseOffsetsUI>`
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};

    ${mediaBreakpointUp('md')} {
        gap: 32px;
    }

    ${mediaBreakpointUp('xl')} {
        gap: 40px;
    }

    ${ProceduresList} {
        margin: 0;
    }

    ${Documents} {
        margin: 0;
        padding: 24px 20px 20px;

        ${mediaBreakpointUp('md')} {
            padding: 32px 24px 24px;
        }

        ${mediaBreakpointUp('xl')} {
            padding: 40px 32px 32px;
        }

        &:not(:first-child) {
            padding-top: 0;
            padding-bottom: 24px;

            ${mediaBreakpointUp('md')} {
                padding-bottom: 32px;
            }

            ${mediaBreakpointUp('xl')} {
                padding-bottom: 40px;
            }
        }
    }
    ${DocumentsWrapper} {
        padding: 0;
    }

    ${ContentSpoiler} {
        ${offset('l', 'margin-left')};
        ${offset('l', 'margin-right')};

        &:first-child {
            ${offset('xl', 'margin-top')};
        }

        &:last-child {
            ${offset('xl', 'margin-bottom')};
        }

        &:not(:last-child) {
            ${offset('xs', 'padding-bottom')};
        }
    }
`;

export const Header = styled.div<{ headerInRow: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    ${({ headerInRow }) =>
        headerInRow &&
        css`
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        `}

    ${mediaBreakpointDown('xl')} {
        position: relative;
        margin: 0 20px;
    }

    ${mediaBreakpointUp('md')} {
        gap: 32px;
    }

    ${mediaBreakpointUp('xl')} {
        gap: 40px;
    }
`;

export const Title = styled.h2`
    ${font('h1')};

    ${mediaBreakpointUp('md')} {
        margin-right: 32px;
        max-width: 542px;
    }

    ${mediaBreakpointUp('xl')} {
        max-width: 896px;
    }
`;

export const Tabs = styled.div`
    display: flex;
    max-width: 100%;
    padding: 4px;
    border-radius: 100px;
    background-color: ${color('white')};

    ${mediaBreakpointUp('xl')} {
        position: relative;
    }
`;

export const Tab = styled.button<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    margin: 0;
    padding: 12px;
    ${font('ui')};
    border-radius: 100px;
    color: ${color('ui-text-default')};
    background-color: ${({ active }) => (active ? color('ui-bg-hover') : color('white'))};
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    ${({ active }) =>
        !active &&
        css`
            ${hover(`
                background-color: ${color('Content-02-hover')};
            `)};
        `};

    ${mediaBreakpointUp('md')} {
        min-height: 52px;
        padding: 0 24px;
        white-space: nowrap;
    }
`;

export const Wrapper = styled.div<{ withoutSideOffsets?: boolean }>`
    position: relative;
    overflow: hidden;

    ${mediaBreakpointDown('xl')} {
        margin: ${({ withoutSideOffsets }) => !withoutSideOffsets && '0 20px'};
    }
`;

export const TabContent = styled.div<{ active?: boolean; bgColor?: Colors }>`
    position: ${({ active }) => (active ? 'relative' : 'absolute')};
    opacity: ${({ active }) => (active ? 1 : 0)};
    background-color: ${({ bgColor }) => bgColor && color(bgColor)};
    border-radius: ${({ bgColor }) => (bgColor ? variables.corners.bigCard : 0)};
    width: 100%;
    overflow: hidden;
    transition: opacity 0.3s ease;
`;

export const TabDescription = styled.p`
    ${font('t1-body-exb')};
    ${offset('xl', 'margin-bottom')};

    ${mediaBreakpointUp('md')} {
        max-width: 592px;
    }
`;

export const More = styled(Tab)<{ show?: boolean; open?: boolean }>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    gap: 10px;
    color: ${({ open }) => (open ? color('link-accent-default') : color('ui-text-default'))};
    transition: color 0.3s ease-in-out;
    flex-shrink: 0;
    white-space: normal;

    ${mediaBreakpointUp('md')} {
        gap: 4px;
        padding: 0 16px;
    }

    ${mediaBreakpointUp('xl')} {
        padding: 0 24px;
    }

    ${hover(`
        color: ${color('link-accent-default')};
        background-color: transparent;
    `)};

    span {
        &:last-of-type {
            display: flex;

            svg {
                width: 10px;
                height: 10px;
                transform: ${({ open }) => (open ? 'rotate(0)' : 'rotate(180deg)')};

                path {
                    stroke: currentColor;
                    transition: stroke 0.3s ease-in-out;
                }
            }
        }
    }
`;

export const Dropdown = styled.div<{ show?: boolean }>`
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 8px;
    padding: 4px 0;
    border-radius: 14px;
    background-color: ${color('white')};
    box-shadow: 0 4px 16px 0 ${color('BlueGray/200')};
    opacity: ${({ show }) => (show ? 1 : 0)};
    pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
    transition: opacity 0.3s ease;

    ${mediaBreakpointUp('xl')} {
        left: auto;
        right: 0;
        width: fit-content;
    }
`;

export const DropdownItem = styled.div<{ active?: boolean }>`
    ${font('ui')};
    padding: 16px 20px;
    text-align: left;
    color: ${({ active }) => (active ? color('link-accent-default') : color('text-primary'))};
    transition: color 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        padding: 20px 24px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${color('divider-onwhite')};
    }

    ${hover(`
        color: ${color('link-accent-default')};
    `)};
`;

export const LinkOnMobile = styled.a`
    display: flex;
    align-items: center;
    gap: 4px;
    ${font('text2-sb')};
    color: ${color('text-primary')};
    opacity: 0.6;
    white-space: nowrap;

    ${mediaBreakpointUp('md')} {
        display: none;
    }

    svg {
        width: 12px;
        height: 12px;
        stroke: currentColor;
    }
`;

export const LinkBottom = styled(RectLink)`
    align-self: center;

    ${mediaBreakpointDown('md')} {
        display: none;
    }
`;
