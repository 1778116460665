import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
    Container,
    Header,
    Title,
    Tabs,
    Tab,
    Wrapper,
    TabDescription,
    TabContent,
    More,
    Dropdown,
    DropdownItem,
    LinkOnMobile,
    LinkBottom
} from './WithTabs.styled';
import { BaseOffsetsUI, BaseUI, IMetrika, LinkI } from '@/interfaces';
import ResizeObserver from 'resize-observer-polyfill';
import { InView } from 'react-intersection-observer';
import { SvgElements } from '@/helpers/icons';
import { sendMetrik } from '@/helpers';
import Link from 'next/link';
import Arrow from '@/icons/arrow.svg';
import { Colors } from '@/style/colors';
import { useRouter } from 'next/router';

export interface WithTabsProps extends BaseUI, BaseOffsetsUI, IMetrika {
    title?: string;
    description?: string[];
    tabs: string[];
    link?: LinkI;
    bgColor?: Colors;
    content: ReactElement[];
    withoutSideOffsets?: boolean;
    headerInRow?: boolean;
    isNumbered?: boolean;
}

const WithTabs: React.FC<WithTabsProps> = ({
    className,
    title,
    description,
    tabs,
    link,
    content,
    bgColor,
    metriksSample,
    topOffset,
    bottomOffset,
    withoutSideOffsets,
    headerInRow = false,
    isNumbered = false
}) => {
    const [activeTab, setActiveTab] = useState<string | null>(tabs[0]);
    const [visibleTabs, setVisibleTabs] = useState<string[]>(tabs);
    const [hiddenTabs, setHiddenTabs] = useState<string[]>([]);
    const [showMore, setShowMore] = useState(false);
    const [tabAdjustment, setTabAdjustment] = useState(false);
    const [heightWrapper, setHeightWrapper] = useState(0);
    const [transitionTabWrapper, setTransitionTabWrapper] = useState('unset');
    const headerRef = useRef<HTMLDivElement | null>(null);
    const tabsRef = useRef<HTMLDivElement | null>(null);
    const moreRef = useRef<HTMLButtonElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { locale } = useRouter();

    useEffect(() => {
        let resizeObserver: ResizeObserver;

        if (headerRef.current && tabsRef.current) {
            const resizeHandler = () => {
                if (headerRef.current && tabsRef.current) {
                    setVisibleTabs(tabs);
                    setHiddenTabs([]);

                    const tabItems = Array.from(tabsRef.current.children) as HTMLElement[];
                    const containerWidth = headerRef.current.offsetWidth;
                    let totalWidth = 0;
                    let lastVisibleIndex = tabItems.length - 1;

                    for (let i = 0; i < tabItems.length; ++i) {
                        totalWidth += tabItems[i].offsetWidth;

                        if (totalWidth + 75 > containerWidth) {
                            lastVisibleIndex = i;
                            break;
                        }
                    }

                    setVisibleTabs(tabs.slice(0, lastVisibleIndex));
                    setHiddenTabs(tabs.slice(lastVisibleIndex));
                }
            };
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(headerRef.current);
            resizeHandler();
        }

        return () => {
            resizeObserver?.disconnect();
        };
    }, [tabs]);

    useEffect(() => {
        if (tabAdjustment) adjustingTabs();
    }, [tabAdjustment]);

    const handleTabClick = (tab: string) => {
        const visibleIndex = visibleTabs.indexOf(tab);
        if (visibleIndex !== -1) return;

        const hiddenIndex = hiddenTabs.indexOf(tab);
        if (hiddenIndex !== -1) {
            const newVisibleTabs = [...visibleTabs];
            const newHiddenTabs = [...hiddenTabs];

            const lastVisibleTab = newVisibleTabs.pop();
            newVisibleTabs.push(tab);

            if (lastVisibleTab) {
                newHiddenTabs[hiddenIndex] = lastVisibleTab;
                newHiddenTabs.unshift(newHiddenTabs.splice(hiddenIndex, 1)[0]);
            } else {
                newHiddenTabs.splice(hiddenIndex, 1);
            }

            setVisibleTabs(newVisibleTabs);
            setHiddenTabs(newHiddenTabs);

            setTabAdjustment(true);
        }
    };

    const adjustingTabs = () => {
        if (headerRef.current && tabsRef.current) {
            const newVisibleTabs = [...visibleTabs];
            const newHiddenTabs = [...hiddenTabs];

            const tabItems = Array.from(tabsRef.current.children) as HTMLElement[];
            const containerWidth = headerRef.current.offsetWidth;
            let totalWidth = 0;

            for (let i = 0; i < tabItems.length; ++i) {
                totalWidth += tabItems[i].offsetWidth;

                if (totalWidth > containerWidth) {
                    const firstVisibleTab = newVisibleTabs.shift();
                    if (firstVisibleTab) newHiddenTabs.push(firstVisibleTab);
                    break;
                }
            }

            setVisibleTabs(newVisibleTabs);
            setHiddenTabs(newHiddenTabs);

            setTabAdjustment(false);
        }
    };

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        setTransitionTabWrapper('height 0.3s ease-in-out');

        if (containerRef.current) {
            const resizeHandler = () => {
                if (containerRef.current) setHeightWrapper(containerRef.current.offsetHeight);
            };
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(containerRef.current);
            resizeHandler();
        }
        const transitionTimeout = setTimeout(() => {
            setTransitionTabWrapper('unset');
        }, 300);

        return () => {
            resizeObserver?.disconnect();
            clearTimeout(transitionTimeout);
        };
    }, [activeTab]);

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (
            tabsRef.current &&
            moreRef.current &&
            !tabsRef.current.contains(event.target as Node) &&
            !moreRef.current.contains(event.target as Node)
        ) {
            setShowMore(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    return (
        <InView triggerOnce={true} threshold={0.25}>
            {({ inView, ref }) => (
                <Container
                    ref={ref}
                    className={`${className} ${inView ? '' : '_prepare'}`}
                    bottomOffset={bottomOffset}
                    topOffset={topOffset}
                >
                    <Header ref={headerRef} headerInRow={headerInRow}>
                        {title && <Title dangerouslySetInnerHTML={{ __html: title }}></Title>}
                        {link && (
                            <Link href={link.href} passHref prefetch={false}>
                                <LinkOnMobile
                                    onClick={
                                        metriksSample &&
                                        (() =>
                                            sendMetrik(metriksSample.event, metriksSample.category, {
                                                [metriksSample.action]: link.text
                                            }))
                                    }
                                >
                                    {locale === 'ru' ? 'Все' : 'All'}
                                    <Arrow />
                                </LinkOnMobile>
                            </Link>
                        )}

                        <Tabs ref={tabsRef}>
                            {visibleTabs.map((tab, index) => (
                                <Tab key={index} active={activeTab === tab} onClick={() => setActiveTab(tab)}>
                                    {isNumbered ? `${index + 1} ${locale === 'ru' ? 'шаг' : 'step'}` : tab}
                                </Tab>
                            ))}
                            <More
                                ref={moreRef}
                                show={hiddenTabs.length > 0}
                                open={showMore}
                                onBlur={() => setShowMore(false)}
                                onClick={() => setShowMore((prevState) => !prevState)}
                            >
                                <span>{locale === 'ru' ? 'Ещё' : 'More'}</span>
                                <span>{SvgElements['arrowUp']}</span>
                                <Dropdown show={showMore} className="drop-down">
                                    {hiddenTabs.map((tab, index) => (
                                        <DropdownItem
                                            key={index}
                                            active={activeTab === tab}
                                            onClick={() => {
                                                setActiveTab(tab);
                                                handleTabClick(tab);
                                            }}
                                        >
                                            {tab}
                                        </DropdownItem>
                                    ))}
                                </Dropdown>
                            </More>
                        </Tabs>
                    </Header>

                    <Wrapper
                        style={{ height: `${heightWrapper}px`, transition: `${transitionTabWrapper}` }}
                        withoutSideOffsets={withoutSideOffsets}
                    >
                        <div ref={containerRef}>
                            {content?.map((item, index) => (
                                <>
                                    <TabContent key={index} active={activeTab === tabs[index]} bgColor={bgColor}>
                                        {description && <TabDescription dangerouslySetInnerHTML={{ __html: description[index] }} />}
                                        {item}
                                    </TabContent>
                                </>
                            ))}
                        </div>
                    </Wrapper>

                    {link && (
                        <LinkBottom
                            {...link}
                            type="fill-white"
                            clickOnButton={
                                metriksSample &&
                                (() =>
                                    sendMetrik(metriksSample.event, metriksSample.category, {
                                        [metriksSample.action]: link.text
                                    }))
                            }
                        />
                    )}
                </Container>
            )}
        </InView>
    );
};

export default WithTabs;
